<h2 mat-dialog-title>History</h2>
<mat-dialog-content style="min-height: 300px; display: flex; flex-direction: column; box-sizing: border-box; padding-top: 10px">
    @if (events) {
        @for (event of events | slice:0:max; track event;) {
            <div class="labeled-box">
                <div class="label">
                    {{ event.timestamp | date:'M/d/yy, h:mm a' }}
                </div>
                {{ event.text }}
            </div>
        }
    } @else {
        <mat-spinner [diameter]="32"></mat-spinner>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="warn" (click)="closeButtonPressed()">Close</button>
    <button mat-button (click)="showLessButtonPressed()" [disabled]="!events || max == 6">Show less</button>
    <button mat-button (click)="loadMoreButtonPressed()" [disabled]="!events || max >= events!.length">Load more</button>
</mat-dialog-actions>