<h2 mat-dialog-title>Include rationale?</h2>
<mat-dialog-content>
    <mat-form-field appearance="outline" style="width: 100%; padding-top: 10px">
        <mat-label>Rationale</mat-label>
        <textarea matInput [formControl]="rationale" placeholder="The front image is quite blurry. Please upload a more in focus image and resubmit"></textarea>
        <mat-hint>This rationale will be visible to the seller</mat-hint>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancelButtonPressed()">Cancel</button>
    <button mat-button (click)="rejectButtonPressed()" color="warn">
        @if ((rationale.value?.length ?? 0) > 0) {
            Reject
        } @else {
            Reject without rationale
        }
    </button>
</mat-dialog-actions>