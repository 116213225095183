import PayoutAccount from './payout-account.model';
import User from './user.model';

export default class SellerProfile {
  id!: number;
  user!: User;
  payoutAccount!: PayoutAccount;
  live!: boolean;
  restricted!: boolean;
  type!: string;
  trusted!: boolean;
  sellerLevel!: number;
  overrideCommissionRateBips!: number;
  overrideSellerFeeCents!: number;
  totalSalesValue!:number;
  usesSkuNumbers!:boolean;
  externalListingSitemapUrl!:string;
  markupExternalListings!:boolean;
  externalListingsMarkupBips!:number;
  offersAllowed!: boolean;
  autoDeclineOffersUnderBips!: number;
  created!: Date;
  updated!: Date;

  get displayName() {
    var labelParts: string[] = [];
    if (this.user.givenName) {
      labelParts.push(this.user.givenName);
    }
    if (this.user.familyName) {
      labelParts.push(this.user.familyName);
    }

    if (this.user.givenName || this.user.familyName) {
      labelParts.push(`(${this.user.email})`);
    } else {
      labelParts.push(`${this.user.email}`);
    }

    return labelParts.join(' ');
  }
}

export const SELLER_PROFILE_TYPES = [
  "RETAIL",
  "PROFESSIONAL"
]
