import { Routes } from '@angular/router';
import { ReviewComponent } from './pages/review/review.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { isAuthenticated } from './guards/auth-guard';

export const routes: Routes = [
    { path: '', component: ReviewComponent, canActivate: [isAuthenticated] },
    { path: 'settings', component: SettingsComponent, canActivate: [isAuthenticated] },
    { path: '**', component: PageNotFoundComponent },
];
