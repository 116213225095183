
import SellerProfile from './seller-profile.model';
import BuyerProfile from './buyer-profile.model';
import Image from './image.model';
import Accessory from './accessory.model';
import IssueResponse from './issue-response.model';
import Tag from './tag.model';

export default class Listing {
  id!: number;
  sellerProfile!: SellerProfile;
  model!: any;
  manufactureMonth!: number;
  manufactureYear!: number;
  purchaseMonth!: number;
  purchaseYear!: number;
  originalOwner!: boolean;
  priceCents!: number;
  promotion!: {
    priceCents: number
    type: string
  };
  created!: Date;
  status!: string;
  condition!: string;
  images!: { type: string, image: Image }[];
  listingChallenge!: {
    id: number,
    hour: number,
    minute: number
  };
  accessories!: Accessory[];
  issues!: IssueResponse[];
  tags!: Tag[];
  inventoryNumber!: string;
  externalUrl!: string;
  externalSyncEnabled!: boolean;
  externalPriceSyncEnabled!: boolean;
  flagged!: boolean;
  internalNotes!: string;
  sellerNotes!: string;
  autoDuplicateOnSale!: boolean;
  activePricingModel!: string;
  lastPublished!: Date;
  privateListingBuyer?: BuyerProfile;
  fixedPriceInfo!: {
    priceCents: number,
    strikethroughPriceCents: number,
    offersAllowed: boolean,
    autoDeclineOffersUnderCents: number,
  }
  auctionInfo!: {
    startDate?: Date,
    endDate?: Date,
    live: boolean,
    ended: boolean,
    startingPriceCents?: number,
    numberOfBids: number,
    nextBidCents?: number,
    estimatedValueRange?: {
      lowerBoundCents: number,
      upperBoundCents: number
    },
    disclaimers: string[],
    essay?: string,
    guarantorBuyerProfile?: BuyerProfile,
    guaranteedBidCents?: number,
    hasReserve?: boolean
  }

  get displayName() {
    var displayName = ""
    if (this.manufactureYear != null) {
      displayName += `${this.manufactureYear} `
    }
    displayName += `${this.model.displayName}`
    return displayName
  }

  get isPublic(): boolean {
    if (this.activePricingModel === 'FIXED_PRICE') {
      return this.lastPublished != null && this.lastPublished != undefined;
    } else if (this.activePricingModel === 'AUCTION') {
      return this.auctionInfo.live || this.auctionInfo.ended
    } else {
      return false
    }
  }
}
