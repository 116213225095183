@if (listing) {
    <div style="display: flex; gap: 40px; margin-left: 40px; margin-right: 40px; padding-bottom: 80px;">
        <div style="width: 600px; min-width: 600px; flex-grow: 0;">
            <app-image-carousel [items]="getImageItems()" (zoom)="zoomImageButtonPressed($event)" (delete)="deleteImageButtonPressed($event)" (swap)="swapImageButtonPressed($event)" />
            <input hidden type="file" accept=".png,.jpg,.jpeg" #imageUploader (change)="onImageUpload($event.target)"/>
        </div>
        <div style="flex-grow: 1;">
            <div style="display: flex; flex: row; align-items: center; gap: 10px; justify-content: space-between;">
                <div style="display: flex; flex: row; align-items: center; gap: 10px;">
                    <h2>Listing #{{ listing.id }}</h2>
                    <button mat-icon-button [matMenuTriggerFor]="linksMenu"><mat-icon>link</mat-icon></button>
                    <mat-menu #linksMenu="matMenu">
                        <button mat-menu-item (click)="viewInCloudConsoleButtonPressed()">View in Cloud Console<mat-icon iconPositionEnd>layers</mat-icon></button>
                        <button mat-menu-item (click)="viewInBezelButtonPressed()" [disabled]="!listing.isPublic">View in Bezel<mat-icon iconPositionEnd>storefront</mat-icon></button>
                    </mat-menu>
                </div>
                <div style="display: flex; flex: row; align-items: center; gap: 10px;">
                    <button mat-icon-button (click)="viewHistoryButtonPressed()"><mat-icon>history</mat-icon></button>
                    @if (listing.flagged) {
                        <button mat-flat-button color="warn" (click)="markAsFlagged(false)" [class.spinner]="isFlagging" [disabled]="isFlagging"><mat-icon>flag</mat-icon> Unflag</button>
                    } @else {
                        <button mat-stroked-button color="warn" (click)="markAsFlagged(true)" [class.spinner]="isFlagging" [disabled]="isFlagging"><mat-icon>flag</mat-icon> Flag</button>
                    }
                </div>
            </div>
            <h3>General Information</h3>
            <div style="display: flex; flex-direction: column; gap: 12px;">
                <div style="display: flex; flex-direction: row; gap: 12px; margin-bottom: 10px;">
                    <mat-chip class="pill">Seller Type: {{ listing.sellerProfile.type }}</mat-chip>
                </div>
                <div style="display: flex; flex-direction: row; gap: 12px; align-items: center;">
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <mat-label>Seller</mat-label>
                        <input matInput [formControl]="seller" required>
                    </mat-form-field>
                    <button mat-raised-button (click)="viewSellerInCloudConsoleButtonPressed()">View in Cloud Console<mat-icon iconPositionEnd>exit_to_app</mat-icon></button>
                </div>
                <div style="display: flex; flex-direction: row; gap: 12px; align-items: center;" #modelPhotoOverlayOrigin>
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <mat-label>Model</mat-label>
                        <div style="display: flex; flex-direction: row;">
                            <img [src]="model.value?.images?.[0]?.url" style="max-height: 30px; margin-right: 6px;; object-fit: contain;" (mouseover)="showModelPhotoOverlay = true" (mouseleave)="showModelPhotoOverlay = false">
                            <ng-template
                                cdkConnectedOverlay
                                [cdkConnectedOverlayOrigin]="modelPhotoOverlayOrigin"
                                [cdkConnectedOverlayOpen]="showModelPhotoOverlay"
                             >
                             <div class="enarge-model-image-container" style="height: 480px; width: 480px;">
                                <img [src]="model.value?.images?.[0]?.url" style="height: 100%; width: 100%; object-fit: contain;">
                             </div>
                            </ng-template>
                            <input matInput placeholder="Search models..." [formControl]="modelFilterControl" [matAutocomplete]="modelFilterAutocomplete" required>
                        </div>
                        @if(modelFilterControl.value) {
                            <button matSuffix mat-icon-button (click)="clearModelSelection()">
                                <mat-icon>close</mat-icon>
                            </button>
                        }
                        <mat-autocomplete #modelFilterAutocomplete="matAutocomplete" (optionSelected)="onModelFilterResultClicked($event)">
                          @for(model of modelFilterResults | async; track model.id) {
                            <mat-option [value]="model">
                                <div style="display: flex; align-items: center;">
                                  @if(model.images?.[0]?.url) {
                                    <img [src]="model.images?.[0]?.url" class="image" style="max-height: 40px;">
                                  }
                                  <span class="title">
                                    {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                                  </span>
                                </div>
                            </mat-option>
                          }
                        </mat-autocomplete>
                      </mat-form-field>

                    <button mat-raised-button (click)="viewModelInCloudConsoleButtonPressed()" [disabled]="!model.value">View in Cloud Console<mat-icon iconPositionEnd>exit_to_app</mat-icon></button>
                </div>
                <div style="display: flex; flex-direction: row; gap: 12px">
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <mat-label>Manufacture Month</mat-label>
                        <mat-select [formControl]="manufactureMonth">
                            <mat-option [value]="null"></mat-option>
                            <mat-option [value]="1">January</mat-option>
                            <mat-option [value]="2">February</mat-option>
                            <mat-option [value]="3">March</mat-option>
                            <mat-option [value]="4">April</mat-option>
                            <mat-option [value]="5">May</mat-option>
                            <mat-option [value]="6">June</mat-option>
                            <mat-option [value]="7">July</mat-option>
                            <mat-option [value]="8">August</mat-option>
                            <mat-option [value]="9">September</mat-option>
                            <mat-option [value]="10">October</mat-option>
                            <mat-option [value]="11">November</mat-option>
                            <mat-option [value]="12">December</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <mat-label>Manufacture Year</mat-label>
                        <mat-select [formControl]="manufactureYear">
                            <mat-option [value]="null"></mat-option>
                            @for(year of getManufactureYearOptions(); track year) {
                                <mat-option [value]="year">
                                    {{year}}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            @if(listing.privateListingBuyer) {
                <h3>Private Listing Context</h3>
                <div style="display: flex; flex-direction: row; gap: 12px">
                    @if(!!listing.privateListingBuyer.id) {
                        <div class="labeled-box">
                            <div class="label">
                            Buyer
                            </div>
                            <mat-chip>
                                {{listing?.privateListingBuyer?.id ?? 0}}
                            </mat-chip>
                        </div>
                    }
                    @if(!!purchaseRequestOffer) {
                        <div class="labeled-box">
                            <div class="label">
                            Purchase Request Offer
                            </div>
                            <mat-chip>
                            {{purchaseRequestOffer?.id}}
                            </mat-chip>
                        </div>
                    }
                    <button mat-raised-button (click)="viewPurchaseRequestInConsoleButtonPressed()">Open in Console<mat-icon iconPositionEnd>exit_to_app</mat-icon></button>
                </div>
            }
            <h3>Accessories</h3>
            <div style="display: flex; flex-direction: column; gap: 12px">
                <mat-form-field appearance="outline">
                    <mat-chip-grid #chipGrid>
                        @for (accessoryId of accessoryIds.value; track accessoryId) {
                            <mat-chip-row
                            (removed)="removeAccessory(accessoryId)"
                            [editable]="false">
                            {{getAccessoryById(accessoryId)?.name}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                            </mat-chip-row>
                        }
                        <input placeholder="Add accessory..."
                                (focus)="onAccessoryInputFocus()"
                                [formControl]="accessoryFilterControl"
                                [matChipInputFor]="chipGrid"
                                [matAutocomplete]="auto"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true"
                                #accessoryInput />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addAccessory($event)">
                            @for (accessory of filteredAccessories | async; track accessory.id) {
                                <mat-option [value]="accessory.id">{{accessory.name}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-chip-grid>
                  </mat-form-field>
            </div>
            <h3>Condition</h3>
            <div style="display: flex; flex-direction: column; gap: 12px">
                <mat-chip-listbox [formControl]="condition" required>
                    <mat-chip-option [value]="'UNWORN'">Unworn</mat-chip-option>
                    <mat-chip-option [value]="'PREOWNED'">Pre-Owned</mat-chip-option>
                </mat-chip-listbox>
                @if(condition.value !== 'UNWORN') {
                    <div style="display: flex; flex-direction: column; gap: 10px; align-items: start;">
                        <span><b>Issues</b></span>
                        <button mat-button color="primary" (click)="addIssueButtonPressed()" [disabled]="condition.value === 'UNWORN'"><mat-icon>add</mat-icon>Add Issue</button>
                        @if((issueResponses.value?.length ?? 0) > 0) {
                            <div style="display: flex; flex-direction: row; gap: 12px; flex-wrap: wrap; justify-content: start;">
                                @for (issueResponse of issueResponses.value; track issueResponse.issueId) {
                                    <mat-card style="display: flex; flex-direction: column; padding: 12px; gap: 10px; justify-content: space-between; width: 300px">
                                        <span><b>{{getIssueById(issueResponse.issueId)?.category}} - {{getIssueById(issueResponse.issueId)?.name}}</b></span>
                                        <span>
                                            <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;">
                                                <mat-label>Description</mat-label>
                                                <textarea matInput [formControl]="issueResponse.answer"  [required]="getIssueById(issueResponse.issueId)?.answerRequired ?? false" placeholder="{{getIssueById(issueResponse.issueId)?.question}}" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </span>
                                        <button mat-button color="warn" (click)="removeIssueButtonPressed(issueResponse.issueId)">Remove</button>
                                    </mat-card>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <h3>Pricing</h3>
            <div style="display: flex; flex-direction: column; gap: 12px;">
                <mat-chip-listbox [formControl]="pricingModel" required>
                    <mat-chip-option [value]="'FIXED_PRICE'">Fixed Price</mat-chip-option>
                    <mat-chip-option [value]="'AUCTION'">Auction</mat-chip-option>
                </mat-chip-listbox>
                @if (pricingModel.value === 'FIXED_PRICE') {
                    <mat-form-field appearance="outline">
                        <mat-label>Price ($)</mat-label>
                        <input matInput placeholder="16000" [formControl]="fixedPrice" required>
                    </mat-form-field>
                    <div style="display: flex; flex-direction: column; gap: 6px;">
                        <span><b>Offers</b></span>
                        <mat-checkbox [formControl]="offersAllowed">Offers Are Allowed</mat-checkbox>
                        <mat-form-field appearance="outline">
                            <mat-label>Auto Decline Offers Under ($)</mat-label>
                            <input matInput placeholder="14000" [formControl]="autoDeclineOffersUnder">
                        </mat-form-field>
                    </div>
                } @else if (pricingModel.value === 'AUCTION') {
                    <div style="display: flex; flex-direction: row; gap: 12px">
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Starting on (UTC)</mat-label>
                            <input matInput type="datetime-local" placeholder="Choose a date" [formControl]="auctionStartDate">
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Ending on (UTC)</mat-label>
                            <input matInput type="datetime-local" placeholder="Choose a date" [formControl]="auctionEndDate">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Starting Price ($)</mat-label>
                        <input matInput placeholder="750" [formControl]="auctionStartingPrice">
                    </mat-form-field>
                    <div style="display: flex; flex-direction: row; gap: 12px">
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Lower Estimate ($)</mat-label>
                            <input matInput placeholder="2000" [formControl]="auctionEstimateLowerBound">
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Upper Estimate ($)</mat-label>
                            <input matInput placeholder="3000" [formControl]="auctionEstimateUpperBound">
                        </mat-form-field>
                    </div>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Lot Essay</mat-label>
                        <textarea matInput placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque luctus eros sit amet diam varius maximus. Nam consequat sem sapien, vitae finibus lectus fringilla non." [formControl]="lotEssay"></textarea>
                    </mat-form-field>
                }
            </div>
            <h3>External Syncing</h3>
            <div style="display: flex; flex-direction: column; gap: 12px">
                <div style="display: flex; flex-direction: row">
                    <mat-checkbox [formControl]="externalSyncingEnabled">External Sync Enabled</mat-checkbox>
                    <mat-checkbox [formControl]="externalPriceSyncingEnabled">External Price Sync Enabled</mat-checkbox>
                </div>
                <div style="display: flex; flex-direction: row; gap: 12px; align-items: center;">
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <mat-label>External URL</mat-label>
                        <input matInput placeholder="https://www.watchwebsite.com/listing/rolex-submariner-12303" [formControl]="externalUrl">
                    </mat-form-field>
                    <button mat-raised-button (click)="viewExternalUrlInNewTabButtonPressed()" [disabled]="!externalUrl.value">Open in New Tab<mat-icon iconPositionEnd>exit_to_app</mat-icon></button>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Seller SKU</mat-label>
                    <input matInput placeholder="ABCD1234" [formControl]="sellerSku">
                </mat-form-field>
            </div>
            <h3>Miscellaneous</h3>
            <div style="display: flex; flex-direction: column; gap: 12px">
                <mat-checkbox [formControl]="automaticallyDuplicateAfterSelling">Automatically Duplicate After Selling</mat-checkbox>
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tags</mat-label>
                    <mat-chip-grid #tagsChipGrid disabled>
                        @for (tagId of tagIds.value; track tagId) {
                            <mat-chip-row [editable]="false">
                                {{getTagById(tagId)?.displayValue}}
                            </mat-chip-row>
                        }
                        <input [matChipInputFor]="tagsChipGrid" />
                    </mat-chip-grid>
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Seller Notes</mat-label>
                    <textarea matInput [formControl]="sellerNotes"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
} @else if (reachedEnd) {
    <div style="min-width: 100%; min-height: 100%; display: flex; align-items: center; justify-content: space-around;">
        <div style="display: flex; flex-direction: column; align-items: center; gap: 12px">
            <h2>Time to kick back and relax. 🎉</h2>
            <div style="display: flex; flex-direction: row; gap: 10px">
                <button mat-stroked-button color="warn" [disabled]="skipOffset === 0" (click)="startOverButtonPressed()">Start Over</button>
                <button mat-stroked-button color="primary" [routerLink]="['/settings']">Modify Filters</button>
            </div>
        </div>
    </div>
} @else {
    <div>
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
}
<div style="position: absolute; z-index: 1000; bottom: 0; left: 0; height: 80px; width: 100%; display: flex; justify-content: space-between; padding-left: 32px; padding-right: 32px; box-sizing: border-box; align-items: center; backdrop-filter: blur(10px); background-color: rgba(255, 252, 249, 0.7);">
    <div style="display: flex; gap: 8px">
        <button mat-icon-button [routerLink]="['/settings']"><mat-icon>settings</mat-icon></button>
        <button mat-stroked-button color="warn" [disabled]="isArchiving || isRejecting || isApproving || skipOffset === 0" (click)="startOverButtonPressed()">Start Over</button>
        <button mat-stroked-button color="accent" [disabled]="!listing || isArchiving || isRejecting || isApproving" (click)="skipButtonPressed()" [matBadge]="stackSize - skipOffset > 0 ? stackSize - skipOffset : null">Skip</button>
    </div>
    <div style="display: flex; gap: 8px">
        @if (formGroup.dirty && listing) {
            <button mat-button color="warn" [disabled]="isArchiving || isRejecting || isApproving"  (click)="undoChangedButtonPressed()">
                Undo Changes
            </button>
        }
        <button mat-stroked-button color="warn" [disabled]="!listing || !formGroup.valid || isArchiving || isRejecting || isApproving" [class.spinner]="isArchiving" (click)="archiveButtonPressed()">
            @if (formGroup.dirty) {
                Save &
            }
            Archive
        </button>
        <button mat-flat-button color="warn" [disabled]="!listing || !formGroup.valid || isArchiving || isRejecting || isApproving" [class.spinner]="isRejecting" (click)="rejectButtonPressed()">
            @if (formGroup.dirty) {
                Save &
            }
            Reject
        </button>
        <button mat-flat-button color="primary" [disabled]="!listing || !formGroup.valid || isArchiving || isRejecting || isApproving" [class.spinner]="isApproving" (click)="approveButtonPressed()">
            @if (formGroup.dirty) {
                Save &
            }
            Approve
        </button>
    </div>
</div>
@if (showImageGallery) {
<app-image-gallery [items]="getImageItems()" [pinnedIndex]="imageGalleryIndex" (close)="showImageGallery=false" style="position: absolute; z-index: 1001; bottom: 0; left: 0; width: 100vw; height: 100vh"></app-image-gallery>
}
<canvas hidden #canvas></canvas>
