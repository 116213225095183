import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Issue from '../models/issue.model';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {
  constructor(private http: HttpClient) { }

  public getIssues(): Observable<Issue[]> {
    return this.http.get<Issue[]>(environment.apiUrl + '/marketplace/issues').pipe(
      map(issues => issues.map(i => Object.assign(new Issue(), i)))
    );
  }
}
