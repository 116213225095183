import Image from './image.model';
import Brand from './brand.model';
import Material from './material.model';
import ClaspType from './clasp-type.model';
import Color from './color.model';
import Complication from './complication.model';
import Gender from './gender.model';
import MovementType from './movement-type.model';
import NumeralType from './numeral-type.model';
import Series from './series.model';
import BraceletStyle from './bracelet-style.model';

export default class Model {
  id!: number;
  name!: string;
  displayName!: string | null;
  brand!: Brand;
  series!: Series | null;
  referenceNumber!: string;
  gender!: Gender;
  description!: string | null;
  releaseYear!: number;
  discontinuationYear!: number | null;
  images!: [Image];
  caseSize!: string;
  caseMaterials!: Material[];
  thickness!: number | null;
  waterResistance!: number | null;
  bezelMaterial!: Material;
  crystal!: Material;
  lugWidth!: number | null;
  dialColor!: Color;
  numerals!: NumeralType;
  complications!: Complication[];
  movementType!: MovementType;
  caliber!: string | null;
  powerReserve!: number | null;
  numberOfJewels!: number;
  braceletStyle!: BraceletStyle | null;
  braceletMaterials!: Material[] | null;
  braceletColor!: Color | null;
  braceletClaspType!: ClaspType | null;
  braceletClaspMaterials!: Material[] | null;
}
