import { Component } from '@angular/core';

import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  constructor(
    private authService: AuthService
  ) {

  }

  cloudConsoleButtonPressed(): void {
    window.open(environment.consoleDomain, "_self");
  }

  kibanaButtonPressed(): void {
    window.open(environment.kibanaDomain, "_self");
  }

  metabaseButtonPressed(): void {
    window.open(environment.metabaseDomain, "_self");
  }

  logoutButtonPressed(): void {
    this.authService.logout({logoutParams: {returnTo: location.origin}})
  }
}
