import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import SellerProfile from '../models/seller-profile.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SellersService {
  constructor(private http: HttpClient) { }

  public getSellerProfiles(type: string | null, sortKey: string | null): Observable<SellerProfile[]> {
    var path = `/marketplace/seller-profiles?expand[]=user`;
    if (!!type) {
      path += `&type=${type}`
    }
    if (!!sortKey) {
      path += `&sort=${sortKey}`
    }
    return this.http.get<SellerProfile[]>(environment.apiUrl + path).pipe(
      map(sellers => sellers.map(s => Object.assign(new SellerProfile(), s)))
    );
  }
}
    