import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import Issue from '../../models/issue.model';

export interface DialogData {
  issues: Issue[];
}

@Component({
  selector: 'app-add-issue-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule
  ],
  templateUrl: './add-issue-dialog.component.html',
  styleUrl: './add-issue-dialog.component.scss'
})
export class AddIssueDialogComponent {
  category = new FormControl<string | null>(null, Validators.required);
  issue = new FormControl<number | null>(null, Validators.required);
  answer = new FormControl<string | null>(null);

  formGroup = new FormGroup({
    category: this.category,
    issue: this.issue,
    answer: this.answer
  });

  private issues: Issue[];

  constructor(
    public dialogRef: MatDialogRef<AddIssueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.issues = data.issues;
  }

  getIssueById(id: number): Issue | undefined {
    return this.issues.find(i => i.id == id);
  }

  getCategoryOptions(): string[] {
    return [...new Set(this.issues.map(i => i.category))];
  }

  getIssuesForCategory(category: string | null): Issue[] {
    if (category == null) {
      return []
    }
    return this.issues.filter(i => i.category === category);
  }

  cancelButtonPressed(): void {
    this.dialogRef.close();
  }

  saveButtonPressed(): void {
    this.dialogRef.close({
      issueId: this.issue.value,
      answer: this.answer.value
    });
  }
}
