import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';

export const isAuthenticated: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);

  return authService.isAuthenticated$.pipe(
    map(authenticated => {
      if (!authenticated) {
        authService.loginWithRedirect({appState: {target: router.url}})
      }
      return authenticated;
    })
  )
};
