import { ApplicationConfig } from '@angular/core';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { routes } from './app.routes';

import { environment } from '../environments/environment';
import { clientHeaderInterceptor } from './interceptors/client-header-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAuth0({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        audience: 'platform-core',
        redirect_uri: window.location.origin
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: 'https://api.sandbox.bezel.cloud/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          },
          {
            uri: 'https://api.bezel.cloud/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          },
          {
            uri: 'http://127.0.0.1:8080/*',
            tokenOptions: {
              authorizationParams: {
                audience: 'platform-core',
              }
            }
          }
        ]
      }
    }),
    provideRouter(routes), 
    provideHttpClient(withInterceptors([
      authHttpInterceptorFn, clientHeaderInterceptor
    ])),
    provideAnimationsAsync(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
};
