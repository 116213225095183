import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export interface ImageItem {
  url: string | null,
  image: InstanceType<typeof Image> | null,
}

@Component({
  selector: 'app-image-gallery',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './image-gallery.component.html',
  styleUrl: './image-gallery.component.scss'
})
export class ImageGalleryComponent {
  @Input('items') items: ImageItem[] = [];
  @Input('pinnedIndex') pinnedIndex: number = 0;

  @Output() close: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (this.items) {
      this.boundPinnedIndexIfNeeded();
    }
  }

  closeButtonPressed(): void {
    this.close.emit();
  }

  moveLeftButtonPressed(): void {
    if (this.pinnedIndex <= 0) {
      this.pinnedIndex = this.items!.length - 1;
      return;
    }
    this.pinnedIndex -= 1;
  }

  moveRightButtonPressed(): void {
    if (this.pinnedIndex >= this.items!.length - 1) {
      this.pinnedIndex = 0;
      return;
    }
    this.pinnedIndex += 1;
  }

  private boundPinnedIndexIfNeeded(): void {
    if (this.items.length == 0) {
      this.pinnedIndex = 0;
      return;
    }

    if (this.pinnedIndex < 0) {
      this.pinnedIndex = 0;
    } else if (this.pinnedIndex >= this.items.length) {
      this.pinnedIndex = this.items.length - 1;
    }
  }
}
