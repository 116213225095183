import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import Listing from '../../models/listing.model';
import { MatButtonModule } from '@angular/material/button';
import User from '../../models/user.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ListingsService } from '../../services/listings.service';
import { DatePipe, SlicePipe } from '@angular/common';
import ListingStatusChangeEvent from '../../models/listing-status-change-event.model';

export interface DialogData {
  listingId: number;
}

export class TimelineEvent {
  text!: string;
  timestamp!: Date;
}

@Component({
  selector: 'app-listing-history-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SlicePipe,
    DatePipe
  ],
  templateUrl: './listing-history-dialog.component.html',
  styleUrl: './listing-history-dialog.component.scss'
})
export class ListingHistoryDialogComponent {
  static DEFAULT_MAX: number = 6;
  private static LOAD_MORE_INCREMENT: number = 6;

  max: number = ListingHistoryDialogComponent.DEFAULT_MAX;

  private listingId!: number

  listing: Listing | null = null;
  events: TimelineEvent[] | null = null;

  constructor(
    public dialogRef: MatDialogRef<ListingHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private listingsService: ListingsService
  ) {
    this.listingId = data.listingId;
    this.listingsService.getListingStatusChangeEvents(this.listingId).subscribe({
      next: events => {
        this.populateEvents(events);
      }, 
      error: error => {
        console.log(error);
      }
    })
  }

  closeButtonPressed(): void {
    this.dialogRef.close();
  }

  showLessButtonPressed(): void {
    this.max = ListingHistoryDialogComponent.DEFAULT_MAX;
  }

  loadMoreButtonPressed(): void {
    this.max += ListingHistoryDialogComponent.LOAD_MORE_INCREMENT;
  }

  private populateEvents(statusHistory: ListingStatusChangeEvent[]) {
    this.events = [];
    for (var event of statusHistory.reverse()) {
      var userString = ""
      if (event.user) {
        let user = Object.assign(new User(), event.user)
        userString = ` by ${user.displayName}`
      }

      var rationaleStringPrefix = ""
      var rationaleStringSuffix = ""
      if (event.rationale) {
        if (event.rationale == event.rationale.toUpperCase()) {
          rationaleStringPrefix = `${event.rationale} ⇢ `;
        } else {
          rationaleStringSuffix = ` with rationale "${event.rationale.slice(0, 160)}${event.rationale.length > 160 ? "..." : ""}"`
        }
      }

      this.events.push({
        text: `${rationaleStringPrefix} Status changed to ${event.newStatus}${userString}${rationaleStringSuffix}`,
        timestamp: event.timestamp,
      })
    }
  }
}
