import {COMMA, ENTER, P} from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import Listing from '../../models/listing.model';
import PurchaseRequestOffer from '../../models/purchase-request-offer.model';
import { ListingsService } from '../../services/listings.service';
import { ImageCarouselComponent, ImageItem } from '../../components/image-carousel/image-carousel.component';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IssuesService } from '../../services/issue.service';
import Issue from '../../models/issue.model';
import { AddIssueDialogComponent } from '../../components/add-issue-dialog/add-issue-dialog.component';
import { AccessoriesService } from '../../services/accessory.service';
import Accessory from '../../models/accessory.model';
import { BehaviorSubject, Observable, debounceTime, forkJoin, map, of, startWith } from 'rxjs';
import { AsyncPipe } from '@angular/common';

import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import Model from '../../models/model.model';
import { ModelsService } from '../../services/models.service';
import { ListingHistoryDialogComponent } from '../../components/listing-history-dialog/listing-history-dialog.component';
import { ImageService } from '../../models/image.service';
import { TagsService } from '../../services/tags.service';
import Tag from '../../models/tag.model';
import { RejectionRationaleDialogComponent } from '../../components/rejection-rationale-dialog/rejection-rationale-dialog.component';
import { ImageGalleryComponent } from '../../components/image-gallery/image-gallery.component';
import { PurchaseRequestService } from '../../services/purchase-requests.service';

interface ImageData {
  imageId: number | null,
  type: string,
  url: string | null,
  image: InstanceType<typeof Image> | null,
}

@Component({
  selector: 'app-review',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSelectModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatBadgeModule,
    OverlayModule,
    MatProgressSpinnerModule,
    ImageCarouselComponent,
    ImageGalleryComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AsyncPipe
  ],
  templateUrl: './review.component.html',
  styleUrl: './review.component.scss'
})
export class ReviewComponent {
  environment = environment;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  issues: Issue[] = [];
  accessories: Accessory[] = [];
  tags: Tag[] | null = null;

  listing: Listing | null = null;

  modelFilterControl = new FormControl<string | null>(null);
  modelFilterResults: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);
  showModelPhotoOverlay = false;

  showImageGallery = false;
  imageGalleryIndex = 0;

  accessoryFilterControl = new FormControl<string | null>(null);
  @ViewChild('accessoryInput') accessoryInput!: ElementRef<HTMLInputElement>;
  filteredAccessories: Observable<Accessory[]>;

  @ViewChild('imageUploader') imageUploader!: ElementRef<HTMLElement>;
  private imageToReplace: number | null = null;
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

  images = new FormControl<ImageData[] | null>(null, [Validators.required]);
  seller = new FormControl<string | null>(null, [Validators.required]);
  model = new FormControl<Model | null>(null, [Validators.required]);
  manufactureMonth = new FormControl<number | null>(null);
  manufactureYear = new FormControl<number | null>(null);
  accessoryIds = new FormControl<number[] | null>(null);
  condition = new FormControl<string | null>(null, [Validators.required]);
  issueResponses = new FormControl<{ issueId: number, answer: FormControl<string | null> }[] | null>(null);
  pricingModel = new FormControl<string | null>(null, [Validators.required]);
  purchaseRequestOffer!: PurchaseRequestOffer | null;
  // FP fields
  fixedPrice = new FormControl<number | null>(null);
  offersAllowed = new FormControl<boolean | null>(null);
  autoDeclineOffersUnder = new FormControl<number | null>(null);
  // Auction fields
  auctionStartDate = new FormControl<string | null>(null);
  auctionEndDate = new FormControl<string | null>(null);
  auctionStartingPrice = new FormControl<number | null>(null);
  auctionEstimateLowerBound = new FormControl<number | null>(null);
  auctionEstimateUpperBound = new FormControl<number | null>(null);
  lotEssay = new FormControl<string | null>(null);
  // --
  externalSyncingEnabled = new FormControl<boolean | null>(null);
  externalPriceSyncingEnabled = new FormControl<boolean | null>(null);
  externalUrl = new FormControl<string | null>(null);
  automaticallyDuplicateAfterSelling = new FormControl<boolean | null>(null);
  sellerSku = new FormControl<string | null>(null);
  sellerNotes = new FormControl<string | null>(null);
  tagIds = new FormControl<number[] | null>(null);

  formGroup = new FormGroup({
    images: this.images,
    seller: this.seller,
    model: this.model,
    manufactureMonth: this.manufactureMonth,
    manufactureYear: this.manufactureYear,
    accessoryIds: this.accessoryIds,
    condition: this.condition,
    issueResponses: this.issueResponses,
    issueResponseAnswers: new FormArray([]),
    pricingModel: this.pricingModel,
    fixedPrice: this.fixedPrice,
    offersAllowed: this.offersAllowed,
    autoDeclineOffersUnder: this.autoDeclineOffersUnder,
    auctionStartDate: this.auctionStartDate,
    auctionEndDate: this.auctionEndDate,
    auctionStartingPrice: this.auctionStartingPrice,
    auctionEstimateLowerBound: this.auctionEstimateLowerBound,
    auctionEstimateUpperBound: this.auctionEstimateUpperBound,
    lotEssay: this.lotEssay,
    externalSyncingEnabled: this.externalSyncingEnabled,
    externalPriceSyncingEnabled: this.externalPriceSyncingEnabled,
    externalUrl: this.externalUrl,
    automaticallyDuplicateAfterSelling: this.automaticallyDuplicateAfterSelling,
    sellerSku: this.sellerSku,
    sellerNotes: this.sellerNotes,
    tagIds: this.tagIds,
  });

  isFlagging: boolean = false;
  isSkipping: boolean = false;
  isArchiving: boolean = false;
  isRejecting: boolean = false;
  isApproving: boolean = false;

  skipOffset: number = 0;
  stackSize: number = 0;
  reachedEnd: boolean = false;

  constructor(
    private listingsService: ListingsService,
    private issuesService: IssuesService,
    private accessoriesService: AccessoriesService,
    private modelsService: ModelsService,
    private settingsService: SettingsService,
    private imageService: ImageService,
    private purchaseRequestService: PurchaseRequestService,
    public dialog: MatDialog,
    private tagsService: TagsService,
  ) {
    this.issuesService.getIssues().subscribe({
      next: issues => {
        this.issues = issues;
      }
    })
    this.accessoriesService.getAccessories().subscribe({
      next: accessories => {
        this.accessories = accessories;
      }
    })
    this.tagsService.getTags(null).subscribe({
      next: tags => {
        this.tags = tags;
      },
      error: error => {
        console.log(error);
      }
    })

    this.filteredAccessories = this.accessoryFilterControl.valueChanges.pipe(
      startWith(null),
      map((filterString: string | null) => (filterString ? this._filterAccessories(filterString) : this.accessories.slice())),
    );
    this.modelFilterControl.valueChanges.pipe(debounceTime(200)).subscribe(value => this.onModelSearchTextChanged(value || ''));

    this.condition.valueChanges.subscribe({
      next: condition => {
        if (condition === 'UNWORN') {
          this.issueResponses.setValue([]);
        } else if (this.listing) {
          this.issueResponses.setValue(this.listing.issues.map((i) => {
            var control = new FormControl<string | null>(i.answer);
            if (i.issue.answerRequired) {
              control.setValidators([Validators.required])
            }
            return {
              issueId: i.issue.id,
              answer: control
            }
          }));
        }
        this._resetIssueResponseAnswersFormControl();
      }
    });
    this.offersAllowed.valueChanges.subscribe({
      next: offersAllowed => {
        if (offersAllowed) {
          this.autoDeclineOffersUnder.enable();
        } else {
          this.autoDeclineOffersUnder.disable();
        }
      }
    });
    this.externalSyncingEnabled.valueChanges.subscribe({
      next: externalSyncingEnabled => {
        if (externalSyncingEnabled) {
          this.externalUrl.enable();
          this.externalPriceSyncingEnabled.enable()
        } else {
          this.externalUrl.disable();
          this.externalPriceSyncingEnabled.disable()
        }
      }
    });

    this.fetchNewListing();
  }

  viewInCloudConsoleButtonPressed(): void {
    window.open(environment.consoleDomain + "/marketplace/listings/" + this.listing?.id, "_blank");
  }

  viewInBezelButtonPressed(): void {
    window.open(environment.bezelDomain + "/listings/" + this.listing?.id, "_blank");
  }

  viewPurchaseRequestInConsoleButtonPressed(): void {
    window.open(environment.consoleDomain + "/marketplace/purchase-requests/" + this.purchaseRequestOffer?.purchaseRequest?.id, "_blank");
  }

  viewHistoryButtonPressed(): void {
    const dialogRef = this.dialog.open(ListingHistoryDialogComponent, {
      data: { listingId: this.listing!.id },
      minWidth: 420,
      maxWidth: 480
    });
  }

  viewSellerInCloudConsoleButtonPressed(): void {
    window.open(environment.consoleDomain + "/marketplace/sellers/" + this.listing?.sellerProfile.id, "_blank");
  }

  viewModelInCloudConsoleButtonPressed(): void {
    window.open(environment.consoleDomain + "/catalog/models/" + this.model.value?.id, "_blank");
  }

  viewExternalUrlInNewTabButtonPressed(): void {
    var url = this.externalUrl.value;
    if (!url?.startsWith('https://') && !url?.startsWith('http://')) {
      url = 'https://' + url
    }
    window.open(url, "_blank");
  }

  getImageItems(): ImageItem[] {
    if (!this.images.value) {
      return [];
    }

    var imageSortOrder = ['FRONT', 'CROWN_SIDE', 'OPPOSITE_CROWN_SIDE', 'BACK', 'BRACELET_TOP', 'BRACELET_BOTTOM', 'CLASP', 'ACCESSORY', 'VERIFICATION']
    return this.images.value.sort((a, b) => imageSortOrder.indexOf(a.type) - imageSortOrder.indexOf(b.type)).map(o => {
      return {
        url: o.url,
        image: o.image,
        canDelete: o.type !== 'FRONT' && o.type !== 'VERIFICATION',
        canSwap: o.type !== 'VERIFICATION'
      }
    })
  }

  zoomImageButtonPressed(index: number): void {
    this.imageGalleryIndex = index;
    this.showImageGallery = true;
  }

  deleteImageButtonPressed(index: number): void {
    var images = this.images.value;
    images!.splice(index, 1);
    this.images.setValue(images);
    this.images.markAsDirty();
  }

  swapImageButtonPressed(index: number): void {
    this.imageToReplace = index;
    let el: HTMLElement = this.imageUploader.nativeElement;
    el.click();
  }

  onImageUpload(target: EventTarget | null): void {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    var input = target as HTMLInputElement;
    Array.prototype.forEach.call(input.files, file => {
      var img = new Image();
      var reader = new FileReader();
      reader.onload = event => {
        img.src = event.target!.result as string;
      }
      reader.readAsDataURL(file);
      if (this.images.value && this.imageToReplace) {
        var images = this.images.value;
        images[this.imageToReplace].image = img;
        images[this.imageToReplace].imageId = null;
        images[this.imageToReplace].url = null;
        this.images.setValue(images);
      }
    });
    this.images.markAsDirty();
    this.imageToReplace = null;
    this.uploadImagesIfNeeded(() => {});
  }

  onModelSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.modelFilterResults.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: result => {
        this.modelFilterResults.next(result.hits.map((h: any) => h.object).filter((m: any) => m != null && m != undefined));
      },
      error: error => {
        console.log(error)
      }
    })
  }

  onModelFilterResultClicked(event: MatAutocompleteSelectedEvent) {
    var model = event.option.value;
    if (this.model.value?.id != model.id) {
      this.model.setValue(model);
      this.model.markAsDirty();
    }
    this.modelFilterControl.setValue(`${model.brand.displayName ?? model.brand.name} ${model.displayName ?? model.name} ${model.referenceNumber}`)
    this.modelFilterResults.next([]);
  }

  clearModelSelection(): void {
    this.modelFilterControl.reset();
    this.model.reset();
    this.model.markAsDirty();
  }

  getManufactureYearOptions(): number[] {
    var startYear = this.model.value?.releaseYear ?? 1900;
    var endYear = this.model.value?.discontinuationYear ?? 2024;

    return [ ...Array(endYear - startYear + 1).keys() ].map( i => i + startYear).reverse();
  }

  getTagById(id: number): Tag | undefined {
    return this.tags?.find(i => i.id == id);
  }

  getAccessoryById(id: number): Accessory | undefined {
    return this.accessories.find(i => i.id == id);
  }

  onAccessoryInputFocus(): void {

  }

  addAccessory(event: MatAutocompleteSelectedEvent): void {
    var accessories: number[] = this.accessoryIds.value ?? [];
    if (event.option.value) {
      const index = accessories.indexOf(event.option.value);
      if (index == -1) {
        accessories.push(event.option.value);
      }
      this.accessoryIds.setValue(accessories);
    }

    this.accessoryInput.nativeElement.value = '';
    this.accessoryFilterControl.setValue(null);
    this.accessoryIds.markAsDirty();
  }

  removeAccessory(accessoryId: number): void {
    var accessories: number[] = this.accessoryIds.value ?? [];
    const index = accessories.indexOf(accessoryId);
    if (index >= 0) {
      accessories.splice(index, 1);
    }
    this.accessoryIds.setValue(accessories);
    this.accessoryIds.markAsDirty();
  }

  getIssueById(id: number): Issue | undefined {
    return this.issues.find(i => i.id == id);
  }

  addIssueButtonPressed(): void {
    const dialogRef = this.dialog.open(AddIssueDialogComponent, {
      data: { issues: this.issues },
      minWidth: 400
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var issueResponses: any[] = this.issueResponses.value ?? [];

        var control = new FormControl<string | null>(result.answer);
        if (this.getIssueById(result.issueId)?.answerRequired) {
          control.setValidators([Validators.required])
        }

        issueResponses.push({
          issueId: result.issueId,
          answer: control
        });
        this.issueResponses.setValue(issueResponses);
        this.issueResponses.markAsDirty();
        this._resetIssueResponseAnswersFormControl();
      }
    });
  }

  removeIssueButtonPressed(issueId: number): void {
    var issueResponses: any[] = [];
    for (var issueResponse of this.issueResponses.value ?? []) {
      if (issueResponse.issueId != issueId) {
        issueResponses.push(issueResponse);
      }
    }
    this.issueResponses.setValue(issueResponses);
    this.issueResponses.markAsDirty();
    this._resetIssueResponseAnswersFormControl();
  }

  undoChangedButtonPressed(): void {
    if (this.listing) {
      this.setupFormForListing(this.listing);
    }
  }

  markAsFlagged(flagged: boolean): void {
    this.isFlagging = true;
    this.listingsService.updateListing(this.listing!.id, {flagged: flagged}).subscribe({
      next: () => {
        this.listing!.flagged = flagged;
        this.isFlagging = false;
      },
      error: error => {
        console.log(error);
        this.isFlagging = false;
      }
    })
  }

  skipButtonPressed(): void {
    this.skipOffset += 1;
    this.fetchNewListing();
  }

  startOverButtonPressed(): void {
    this.skipOffset = 0;
    this.fetchNewListing();
  }

  archiveButtonPressed(): void {
    this.isArchiving = true;
    this.saveIfNeeded(error => {
      if (error) {
        console.log(error);
        this.isArchiving = false;
        return
      }

      this.listingsService.archiveListing(this.listing!.id, '').subscribe({
        next: () => {
          this.isArchiving = false;
          this.fetchNewListing();
        },
        error: (error) => {
          this.isArchiving = false;
          console.log(error);
        }
      })
    })
  }

  rejectButtonPressed(): void {
    const dialogRef = this.dialog.open(RejectionRationaleDialogComponent, {
      data: {},
      minWidth: 420
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isRejecting = true;
        this.saveIfNeeded(error => {
          if (error) {
            console.log(error);
            this.isRejecting = false;
            return
          }

          this.listingsService.rejectListing(this.listing!.id, result.rationale).subscribe({
            next: () => {
              this.isRejecting = false;
              this.fetchNewListing();
            },
            error: (error) => {
              this.isRejecting = false;
              console.log(error);
            }
          })
        })
      }
    });
  }

  approveButtonPressed(): void {
    this.isApproving = true;
    this.saveIfNeeded(error => {
      if (error) {
        console.log(error);
        this.isApproving = false;
        return
      }

      this.listingsService.approveListing(this.listing!.id, '').subscribe({
        next: () => {
          this.isApproving = false;
          this.fetchNewListing();
        },
        error: (error) => {
          this.isApproving = false;
          console.log(error);
        }
      })
    })
  }

  private saveIfNeeded(callback: (error: any) => void): void {
    if (!this.formGroup.dirty) {
      callback(null);
      return;
    }

    this.uploadImagesIfNeeded((error) => {
      if (error) {
        callback(error);
        return;
      }

      var updates: any = {};
      if (this.images.dirty) {
        // TODO: Combine this with 'imageSortOrder' above.
        var imageTypes = ['FRONT', 'CROWN_SIDE', 'OPPOSITE_CROWN_SIDE', 'BACK', 'BRACELET_TOP', 'BRACELET_BOTTOM', 'CLASP', 'ACCESSORY', 'VERIFICATION']
        updates.images = [];
        for (var imageType of imageTypes) {
          var imageData = this.images.value?.find(i => i.type == imageType);
          updates.images.push({
            image: imageData?.imageId ?? null,
            type: imageType
          })
        }
      }
      if (this.model.dirty) {
        updates.model = this.model.value!.id;
      }
      if (this.manufactureMonth.dirty) {
        updates.manufactureMonth = this.manufactureMonth.value;
      }
      if (this.manufactureYear.dirty) {
        updates.manufactureYear = this.manufactureYear.value;
      }
      if (this.accessoryIds.dirty) {
        updates.accessories = this.accessoryIds.value;
      }
      if (this.condition.dirty) {
        updates.condition = this.condition.value;
      }
      if (this.issueResponses.dirty) {
        if (this.issueResponses.value) {
          updates.issues = this.issueResponses.value.map(i => { return { issue: i.issueId, answer: i.answer.value }; })
        } else {
          updates.issues = null;
        }
      }
      if (this.pricingModel.dirty) {
        updates.activePricingModel = this.pricingModel.value;
      }
      if (this.fixedPrice.dirty || this.offersAllowed.dirty || this.autoDeclineOffersUnder.dirty) {
        var fixedPriceInfo: any = {};
        if (this.fixedPrice.dirty) {
          fixedPriceInfo.priceCents = this.fixedPrice.value! * 100;
        }
        if (this.offersAllowed.dirty) {
          fixedPriceInfo.offersAllowed = this.offersAllowed.value;
        }
        if (this.autoDeclineOffersUnder.dirty) {
          if (this.autoDeclineOffersUnder.value) {
            fixedPriceInfo.autoDeclineOffersUnderCents = this.autoDeclineOffersUnder.value * 100;
          } else {
            fixedPriceInfo.autoDeclineOffersUnderCents = null;
          }
        }
        updates.fixedPriceInfo = fixedPriceInfo;
      }
      if (this.auctionEstimateLowerBound.dirty || this.auctionEstimateUpperBound.dirty || this.lotEssay.dirty) {
        var auctionInfo: any = {};
        if (this.auctionEstimateLowerBound.dirty || this.auctionEstimateUpperBound.dirty) {
          auctionInfo.estimatedValueRange = {
            lowerBoundCents: this.auctionEstimateLowerBound.value ? this.auctionEstimateLowerBound.value  * 100 : null,
            upperBoundCents: this.auctionEstimateUpperBound.value ? this.auctionEstimateUpperBound.value  * 100 : null
          }
        }
        if (this.lotEssay.dirty) {
          auctionInfo.essay = this.lotEssay.value;
        }
        updates.auctionInfo = auctionInfo;
      }
      if (this.externalSyncingEnabled.dirty) {
        updates.externalSyncEnabled = this.externalSyncingEnabled.value;
      }
      if (this.externalPriceSyncingEnabled.dirty) {
        updates.externalPriceSyncEnabled = this.externalPriceSyncingEnabled.value;
      }
      if (this.externalUrl.dirty) {
        if (this.externalUrl.value) {
          updates.externalUrl = this.externalUrl.value;
        } else {
          updates.externalUrl = null;
        }
      }
      if (this.automaticallyDuplicateAfterSelling.dirty) {
        updates.autoDuplicateOnSale = this.automaticallyDuplicateAfterSelling.value;
      }
      if (this.sellerSku.dirty) {
        if (this.sellerSku.value) {
          updates.inventoryNumber = this.sellerSku.value;
        } else {
          // TODO(PLA-730): Replace w/ null when platform issue is resolved.
          updates.inventoryNumber = '';
        }
      }
      this.listingsService.updateListing(this.listing!.id, updates).subscribe({
        next: () => {
          callback(null);
        },
        error: (error) => {
          callback(error);
        }
      });
    })
  }

  private uploadImagesIfNeeded(callback: (error: any) => void): void {
    var imageUploads: Observable<number | null>[] = [];
    for (var imageData of this.images.value ?? []) {
      if (imageData.imageId) {
        imageUploads.push(of(imageData.imageId));
      } else if (imageData.image) {
        var croppedImage = this.cropToSquare(imageData.image);
        imageUploads.push(this.imageService.uploadImage(croppedImage));
      } else {
        imageUploads.push(of(null));
      }
    }
    forkJoin(imageUploads).subscribe({
      next: imageIds => {
        var images = this.images.value ?? [];
        images.forEach(function (imageData, i) {
          imageData.imageId = imageIds[i];
        });
        this.images.setValue(images);
        callback(null);
      },
      error: error => {
        callback(error);
      }
    })
  }

  private cropToSquare(image: InstanceType<typeof Image>): InstanceType<typeof Image> {
    var size = Math.min(image.width, image.height);

    var canvas: HTMLCanvasElement = this.canvas.nativeElement;
    canvas.width = size;
    canvas.height = size;

    var context = canvas.getContext("2d");
    context!.drawImage(image, (image.width - size) / 2, (image.height - size) / 2, size, size, 0, 0, size, size);

    var image = new Image();
    image.src = canvas.toDataURL();
    image.width = size;
    image.height = size;
    return image;
  }

  private fetchNewListing() {
    this.reachedEnd = false;
    this.listing = null;
    this.listingsService.getListings({
      start: this.skipOffset,
      limit: 1,
      status: 'PENDING_REVIEW',
      sortKey: 'created',
      sellerProfileFilter: this.settingsService.sellerProfileFilters ?? undefined,
      sellerTypes: this.settingsService.sellerTypeFilters ?? undefined,
      models: this.settingsService.modelFilters ?? undefined,
      conditions: this.settingsService.conditionFilters ?? undefined,
      activePricingModel: this.settingsService.activePricingModelFilter ?? undefined,
      flagged: this.settingsService.flaggedFilter != null ? this.settingsService.flaggedFilter : undefined,
      tags: this.settingsService.tagFilters ?? undefined,
      viewPrivate: this.settingsService.privateFilter ?? undefined,
    }).subscribe({
      next: (response) => {
        this.listing = response.data[0];
        this.stackSize = response.totalCount;
        if (this.listing) {
          this.reachedEnd = false;
          this.setupFormForListing(this.listing);
        } else {
          this.reachedEnd = true;
        }

        if (this.listing && this.listing.privateListingBuyer != null) {
          this.purchaseRequestService.getPurchaseRequestOffers(null, null, [this.listing.id], false).subscribe({
            next: (response: any) => {
              if (response.data != null && response.data.length > 0) {
                this.purchaseRequestOffer = response.data[0];
              }
            },
            error: error => {
              console.log(error);
            }
          });
        }
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  private setupFormForListing(listing: Listing) {
    this.formGroup.reset();
    this.images.setValue(listing.images.map(o => {
      return {
        imageId: o.image.id,
        url: o.image.rawUrl,
        type: o.type,
        image: null
      }
    }))
    this.seller.setValue(`${listing.sellerProfile.user.givenName} ${listing.sellerProfile.user.familyName} (${listing.sellerProfile.user.email})`);
    this.seller.disable();
    this.model.setValue(listing.model);
    this.modelFilterControl.setValue(`${listing.model.brand.displayName ?? listing.model.brand.name} ${listing.model.displayName ?? listing.model.name} ${listing.model.referenceNumber}`);
    this.manufactureMonth.setValue(listing.manufactureMonth);
    this.manufactureYear.setValue(listing.manufactureYear);
    this.accessoryIds.setValue(listing.accessories.map(a => a.id));
    this.condition.setValue(listing.condition);

    this.issueResponses.setValue(listing.issues.map((i) => {
      var control = new FormControl<string | null>(i.answer);
      if (i.issue.answerRequired) {
        control.setValidators([Validators.required])
      }
      return {
        issueId: i.issue.id,
        answer: control
      }
    }));
    this._resetIssueResponseAnswersFormControl();

    this.pricingModel.setValue(listing.activePricingModel);
    if (listing.fixedPriceInfo?.priceCents) {
      this.fixedPrice.setValue(listing.fixedPriceInfo?.priceCents / 100);
    } else {
      this.fixedPrice.setValue(null);
    }
    this.offersAllowed.setValue(listing.fixedPriceInfo?.offersAllowed);
    if (listing.fixedPriceInfo?.autoDeclineOffersUnderCents) {
      this.autoDeclineOffersUnder.setValue(listing.fixedPriceInfo?.autoDeclineOffersUnderCents / 100);
    } else {
      this.autoDeclineOffersUnder.setValue(null);
    }
    if (listing.auctionInfo?.startDate) {
       this.auctionStartDate.setValue(new Date(listing.auctionInfo.startDate).toISOString().substring(0, 16));
    } else {
      this.auctionStartDate.setValue(null);
    }
    this.auctionStartDate.disable();
    if (listing.auctionInfo?.endDate) {
      this.auctionEndDate.setValue(new Date(listing.auctionInfo.endDate).toISOString().substring(0, 16));
    } else {
      this.auctionEndDate.setValue(null);
    }
    this.auctionEndDate.disable();
    if (listing.auctionInfo?.startingPriceCents) {
      this.auctionStartingPrice.setValue(listing.auctionInfo?.startingPriceCents / 100);
    } else {
      this.auctionStartingPrice.setValue(null);
    }
    this.auctionStartingPrice.disable();
    if (listing.auctionInfo?.estimatedValueRange?.lowerBoundCents) {
      this.auctionEstimateLowerBound.setValue(listing.auctionInfo.estimatedValueRange?.lowerBoundCents / 100);
    } else {
      this.auctionEstimateLowerBound.setValue(null);
    }
    if (listing.auctionInfo?.estimatedValueRange?.upperBoundCents) {
      this.auctionEstimateUpperBound.setValue(listing.auctionInfo.estimatedValueRange?.upperBoundCents / 100);
    } else {
      this.auctionEstimateUpperBound.setValue(null);
    }
    this.lotEssay.setValue(listing.auctionInfo?.essay ?? null);
    this.externalSyncingEnabled.setValue(listing.externalSyncEnabled);
    this.externalPriceSyncingEnabled.setValue(listing.externalPriceSyncEnabled);
    this.externalUrl.setValue(listing.externalUrl);
    this.automaticallyDuplicateAfterSelling.setValue(listing.autoDuplicateOnSale);
    this.sellerSku.setValue(listing.inventoryNumber);
    this.sellerNotes.setValue(listing.sellerNotes);
    this.sellerNotes.disable();
    this.tagIds.setValue(listing.tags.map(t => t.id));
  }

  private _filterAccessories(value: string | null): Accessory[] {
    if (value == null) {
      return this.accessories;
    }
    if (typeof value !== 'string') {
      return []
    }
    const filterValue = value.toLowerCase();
    var accessoriesIds: number[] = this.accessoryIds.value ?? [];
    return this.accessories.filter(accessory => accessory.name.toLowerCase().includes(filterValue) && accessoriesIds.indexOf(accessory.id) === -1);
  }

  private _resetIssueResponseAnswersFormControl() {
    while (this.formGroup.controls.issueResponseAnswers.length !== 0) {
      this.formGroup.controls.issueResponseAnswers.removeAt(0)
    }
    for (var issueResponse of this.issueResponses.value ?? []) {
      this.formGroup.controls.issueResponseAnswers.push(issueResponse.answer as never);
    }
  }
}
