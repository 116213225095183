import PurchaseRequest from './purchase-request.model';
import Listing from './listing.model';

export default class PurchaseRequestOffer {
    id!: number;
    purchaseRequest!: PurchaseRequest;
    listing!: Listing;
    offerPriceCents!: number;
    strikethroughPriceCents!: number;
    status!: string;
    get statusEng(): string {
        return PURCHASE_REQUEST_OFFER_STATUS_ENG[PURCHASE_REQUEST_OFFER_STATUS.indexOf(this.status.toUpperCase())];
      }
    onRejectAction!: string;
    expiration!: Date;
    created!: Date;
    updated!: Date;
    get isEditable(): boolean {
      return this.status != "EXPIRED" && this.status != "REJECTED";
    }

  }
  
  export const PURCHASE_REQUEST_OFFER_STATUS = [
    "PENDING_REVIEW",
    "AWAITING_RESPONSE",
    "ACCEPTED",
    "REJECTED",
    "EXPIRED"
  ]

  export const PURCHASE_REQUEST_OFFER_STATUS_ENG = [
    "Pending Review",
    "Awaiting Buyer Response",
    "Accepted",
    "Rejected",
    "Expired"
  ]

export const ON_REJECT_ACTION = [
    "BECOME_PUBLIC",
    "ARCHIVE"
]