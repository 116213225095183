import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import SellerProfile from '../../models/seller-profile.model';
import { BehaviorSubject, Observable, debounceTime, map, startWith } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { SellersService } from '../../services/sellers.service';
import { SettingsService } from '../../services/settings.service';
import { environment } from '../../../environments/environment';
import Tag from '../../models/tag.model';
import { TagsService } from '../../services/tags.service';
import { ModelsService } from '../../services/models.service';
import Model from '../../models/model.model';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AsyncPipe
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  environment = environment;
  
  sellers: SellerProfile[] | null = null;
  sellerProfileFilterControl = new FormControl<string | null>(null);
  @ViewChild('sellerProfileInput') sellerProfileInput!: ElementRef<HTMLInputElement>;
  filteredSellerProfiles: Observable<SellerProfile[]>;

  modelFilterControl = new FormControl<string | null>(null);
  @ViewChild('modelInput') modelInput!: ElementRef<HTMLInputElement>;
  filteredModels: BehaviorSubject<Model[]> = new BehaviorSubject<Model[]>([]);

  tags: Tag[] | null = null;

  sellerProfileIds = new FormControl<number[] | null>(null);
  sellerTypes = new FormControl<string[] | null>(null);
  models = new FormControl<Model[] | null>(null);
  conditions = new FormControl<string[] | null>(null);
  activePricingModel = new FormControl<string | null>(null);
  flagged = new FormControl<boolean | null>(null);
  tagIds = new FormControl<number[] | null>(null);
  private = new FormControl<string | null>(null);

  formGroup = new FormGroup({
    sellerProfileIds: this.sellerProfileIds,
    sellerTypes: this.sellerTypes,
    models: this.models,
    conditions: this.conditions,
    activePricingModel: this.activePricingModel,
    flagged: this.flagged,
    tagIds: this.tagIds,
    private: this.private,
  });

  constructor(
    private sellersService: SellersService,
    private modelsService: ModelsService,
    private settingsService: SettingsService,
    private tagsService: TagsService,
  ) {
    this.sellersService.getSellerProfiles(null, null).subscribe({
      next: sellers => {
        this.sellers = sellers;
      },
      error: error => {
        console.log(error);
      }
    })
    this.filteredSellerProfiles = this.sellerProfileFilterControl.valueChanges.pipe(
      startWith(null),
      map((filterString: string | null) => {
        if (!this.sellers) {
          return [];
        }

        if (!filterString) {
          return [];
        }

        if (!(typeof filterString === 'string')) {
          return []
        }

        return this.sellers.filter(seller => (this.sellerProfileIds.value ?? []).indexOf(seller.id) === -1).filter(seller => seller.displayName.toLowerCase().includes(filterString.toLowerCase()))
      }),
    );
    this.modelFilterControl.valueChanges.pipe(debounceTime(200)).subscribe(value => this.onModelSearchTextChanged(value || ''));
    this.tagsService.getTags(null).subscribe({
      next: tags => {
        this.tags = tags;
      },
      error: error => {
        console.log(error);
      }
    })

    this.sellerProfileIds.valueChanges.subscribe({
      next: sellerProfileIds => {
        this.settingsService.sellerProfileFilters = sellerProfileIds;
      }
    })
    this.sellerProfileIds.setValue(this.settingsService.sellerProfileFilters);
    this.models.valueChanges.subscribe({
      next: models => {
        if (models && models.length > 0) {
          this.settingsService.modelFilters = models.map(m => m.id);
        } else {
          this.settingsService.modelFilters = null;
        }
      }
    })
    this.sellerTypes.valueChanges.subscribe({
      next: sellerTypes => {
        this.settingsService.sellerTypeFilters = sellerTypes;
      }
    })
    this.sellerTypes.setValue(this.settingsService.sellerTypeFilters);
    if (this.settingsService.modelFilters && this.settingsService.modelFilters.length > 0) {
      this.modelsService.getModels(this.settingsService.modelFilters).subscribe({
        next: models => {
          this.models.setValue(models);
        },
        error: error => {
          console.log(error);
        }
      })
    }
    this.conditions.valueChanges.subscribe({
      next: conditions => {
        this.settingsService.conditionFilters = conditions;
      }
    })
    this.conditions.setValue(this.settingsService.conditionFilters);
    this.activePricingModel.valueChanges.subscribe({
      next: activePricingModel => {
        this.settingsService.activePricingModelFilter = activePricingModel;
      }
    })
    this.activePricingModel.setValue(this.settingsService.activePricingModelFilter);
    this.flagged.valueChanges.subscribe({
      next: flagged => {
        this.settingsService.flaggedFilter = flagged;
      }
    })
    this.flagged.setValue(this.settingsService.flaggedFilter);
    this.tagIds.valueChanges.subscribe({
      next: tagIds => {
        this.settingsService.tagFilters = tagIds;
      }
    })
    this.tagIds.setValue(this.settingsService.tagFilters);
    this.private.valueChanges.subscribe({
      next: value => {
        this.settingsService.privateFilter = value;
      }
    });
    this.private.setValue(this.settingsService.privateFilter);
  }

  getSellerById(id: number): SellerProfile | undefined {
    return this.sellers?.find(i => i.id == id);
  }

  addSellerFilter(event: MatAutocompleteSelectedEvent): void {
    var sellerProfileIds: number[] = this.sellerProfileIds.value ?? [];
    if (event.option.value) {
      const index = sellerProfileIds.indexOf(event.option.value);
      if (index == -1) {
        sellerProfileIds.push(event.option.value);
      }
      this.sellerProfileIds.setValue(sellerProfileIds);
    }
    
    this.sellerProfileInput.nativeElement.value = '';
    this.sellerProfileFilterControl.setValue(null);
  }

  removeSellerFilter(sellerProfileId: number): void {
    var sellerProfileIds: number[] = this.sellerProfileIds.value ?? [];
    const index = sellerProfileIds.indexOf(sellerProfileId);
    if (index >= 0) {
      sellerProfileIds.splice(index, 1);
    }
    this.sellerProfileIds.setValue(sellerProfileIds);
  }

  addSellerTypeFilter(event: MatAutocompleteSelectedEvent): void {
    var sellerTypes: string[] = this.sellerTypes.value ?? [];
    if (event.option.value) {
      const index = sellerTypes.indexOf(event.option.value);
      if (index == -1) {
        sellerTypes.push(event.option.value);
      }
      this.sellerTypes.setValue(sellerTypes);
    }
  }

  removeSellerTypeFilter(sellerType: string): void {
    var sellerTypes: string[] = this.sellerTypes.value ?? [];
    const index = sellerTypes.indexOf(sellerType);
    if (index >= 0) {
      sellerTypes.splice(index, 1);
    }
    this.sellerTypes.setValue(sellerTypes);
  }

  onModelSearchTextChanged(query: string) {
    if (query.length < 2) {
      this.filteredModels.next([]);
      return;
    }

    this.modelsService.searchModels(query).subscribe({
      next: result => {
        this.filteredModels.next(result.hits.map((h: any) => h.object));
      },
      error: error => {
        console.log(error)
      }
    })
  }

  addModelFilter(event: MatAutocompleteSelectedEvent): void {
    var models: Model[] = this.models.value ?? [];
    if (event.option.value) {
      const index = models.indexOf(event.option.value);
      if (index == -1) {
        models.push(event.option.value);
      }
      this.models.setValue(models);
    }
    this.modelInput.nativeElement.value = '';
    this.modelFilterControl.setValue(null);
  }

  removeModelFilter(model: Model): void { 
    var models: Model[] = this.models.value ?? [];
    const index = models.indexOf(model);
    if (index >= 0) {
      models.splice(index, 1);
    }
    this.models.setValue(models);
  }

  addCondition(event: MatAutocompleteSelectedEvent): void {
    console.log(event.option.value);
    var conditions: string[] = this.conditions.value ?? [];
    if (event.option.value) {
      const index = conditions.indexOf(event.option.value);
      if (index == -1) {
        conditions.push(event.option.value);
      }
      this.conditions.setValue(conditions);
    }
  }

  removeCondition(condition: string): void { 
    var conditions: string[] = this.conditions.value ?? [];
    const index = conditions.indexOf(condition);
    if (index >= 0) {
      conditions.splice(index, 1);
    }
    this.conditions.setValue(conditions);
  }

  getTagById(id: number): Tag | undefined {
    return this.tags?.find(i => i.id == id);
  }

  addTag(event: MatAutocompleteSelectedEvent): void {
    var tagIds: number[] = this.tagIds.value ?? [];
    if (event.option.value) {
      const index = tagIds.indexOf(event.option.value);
      if (index == -1) {
        tagIds.push(event.option.value);
      }
      this.tagIds.setValue(tagIds);
    }
  }

  removeTag(tagId: number): void { 
    var tagIds: number[] = this.tagIds.value ?? [];
    const index = tagIds.indexOf(tagId);
    if (index >= 0) {
      tagIds.splice(index, 1);
    }
    this.tagIds.setValue(tagIds);
  }

  resetFiltersButtonPressed(): void {
    this.formGroup.reset();
  }
}
