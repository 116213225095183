<div style="display: flex; flex-direction: column; gap: 6px">
    <div style="width: 100%; aspect-ratio : 1 / 1; background-color: #F2F2F2; position: relative; overflow: hidden;">
        <img [src]="items[pinnedIndex].image?.src ?? items[pinnedIndex].url" style="width: 100%; height: 100%; object-fit: cover;"/>
        <div style="display: flex; flex-direction: row; gap: 8px; position: absolute; left: 12px; bottom: 12px">
            <button mat-mini-fab color="primary" (click)="swapButtonPressed()" [disabled]="!items[pinnedIndex].canSwap"><mat-icon>cached</mat-icon></button>
            <button mat-mini-fab color="primary" (click)="deleteButtonPressed()" [disabled]="!items[pinnedIndex].canDelete"><mat-icon>delete</mat-icon></button>
        </div>
        <button mat-mini-fab color="primary" style="position: absolute; right: 12px; top: 12px;" (click)="zoomButtonPressed()"><mat-icon>fit_screen</mat-icon></button>
        <button mat-mini-fab color="primary" style="position: absolute; left: 12px; top: 50%; transform: translateY(-50%);" (click)="moveLeftButtonPressed()"><mat-icon>chevron_left</mat-icon></button>
        <button mat-mini-fab color="primary" style="position: absolute; right: 12px; top: 50%; transform: translateY(-50%);" (click)="moveRightButtonPressed()"><mat-icon>chevron_right</mat-icon></button>
    </div>
    <div style="display: flex; flex-direction: row; gap: 6px;">
        @for (item of items; track item.url; let idx = $index) {
            <div style="height: 70px; width: 70px; box-sizing: border-box; " (mouseover)='onHover(idx)' [ngClass]="{ 'selected-image': idx === pinnedIndex }">
                <img [src]="item.image?.src ?? item.url" style="width: 100%; height: 100%; object-fit: cover; background-color: #F2F2F2;"/>
            </div>
        }
    </div>
</div>