<h2 mat-dialog-title>Add issue</h2>
<mat-dialog-content>
    <div style="display: flex; flex-direction: column; gap: 10px; padding-top: 10px">
        <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select [formControl]="category" required>
                @for (c of getCategoryOptions(); track c) {
                    <mat-option [value]="c">
                        {{c}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        @if (category.value) {
            <mat-form-field appearance="outline">
                <mat-label>Issue</mat-label>
                <mat-select [formControl]="issue" required>
                    @for (issue of getIssuesForCategory(category.value); track issue.id) {
                        <mat-option [value]="issue.id">
                            {{issue.name}}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            @if (issue.value) {
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput [formControl]="answer" [required]="getIssueById(issue.value ?? -1)?.answerRequired ?? false" placeholder="{{getIssueById(issue.value ?? -1)?.question}}"></textarea>
                    @if (getIssueById(issue.value ?? -1)?.answerRequired ?? false) {
                        <mat-hint>A description is required</mat-hint>
                    }
                </mat-form-field>
            }
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancelButtonPressed()">Cancel</button>
    <button mat-button (click)="saveButtonPressed()" [disabled]="!formGroup.valid">Save</button>
</mat-dialog-actions>