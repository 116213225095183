import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

export const clientHeaderInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  if (!req.url.includes(environment.apiUrl)) {
    return next(req);
  }

  const modifiedReq = req.clone({
    headers: req.headers.set('X-Client', 'loupe-web')
  });
  return next(modifiedReq);
};

