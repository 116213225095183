import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Accessory from '../models/accessory.model';

@Injectable({
  providedIn: 'root'
})
export class AccessoriesService {
  constructor(private http: HttpClient) { }

  public getAccessories(): Observable<Accessory[]> {
    return this.http.get<Accessory[]>(environment.apiUrl + '/catalog/accessories').pipe(
      map(issues => issues.map(i => Object.assign(new Accessory(), i)))
    );
  }
}
