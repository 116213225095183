@if ((authService.isLoading$ | async) === false) {
    <div style="height: 100vh; position: relative; overflow: none;">
        <div style="position: absolute; top: 0; left: 0; height: 60px; width: 100%; z-index: 100;">
            <app-navbar />
        </div>
        <div style="height: 100%; z-index: 50; overflow: auto; box-sizing: border-box; padding-top: 60px;">
            <router-outlet />
        </div>
    </div>    
}
