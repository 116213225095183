import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import PurchaseRequestOffer from '../models/purchase-request-offer.model';

@Injectable({
    providedIn: 'root'
})
export class PurchaseRequestService {
  constructor(private http: HttpClient) { }

  public getPurchaseRequestOffers(ids: number[] | null, purchaseRequestIds: number[] | null, listingIds: number[] | null, expandPurchaseRequest: boolean): Observable<any> {
    var path = "/marketplace/purchase-request-offers?expand[]=listing&expand[]=listing.model";
    if (expandPurchaseRequest) {
      path = path + "&expand[]=purchaseRequest";
    }

    if (!!ids) {
      ids.forEach(id => path += `&id[]=${id}`);
    }
    if (!!purchaseRequestIds) {
      purchaseRequestIds.forEach(pr => path += `&purchaseRequest[]=${pr}`);
    }
    if (!!listingIds) {
      listingIds.forEach(l => path += `&listing[]=${l}`);
    }
    return this.http.get<PurchaseRequestOffer[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
        map(response => {
          return {
            data: response.body!.map(pro => this.toPRO(pro)),
            totalCount:  response.headers.get('X-Total-Count')
          }
        })
      );
  }

  private toPRO(fetchedPRO: any): PurchaseRequestOffer {
    var pr = Object.assign(new PurchaseRequestOffer(), fetchedPRO);
    return pr;
  }
}
