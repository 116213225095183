import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
    sellerProfileFilters: number[] | null = null;
    sellerTypeFilters: string[] | null = null;
    modelFilters: number[] | null = null;
    conditionFilters: string[] | null = null;
    activePricingModelFilter: string | null = null;
    flaggedFilter: boolean | null = null;
    tagFilters: number[] | null = null;
    privateFilter: string | null = "include_private";
}
