import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface DialogData {}

@Component({
  selector: 'app-rejection-rationale-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule
  ],
  templateUrl: './rejection-rationale-dialog.component.html',
  styleUrl: './rejection-rationale-dialog.component.scss'
})
export class RejectionRationaleDialogComponent {
  rationale = new FormControl<string | null>(null);

  constructor(
    public dialogRef: MatDialogRef<RejectionRationaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    
  }

  cancelButtonPressed(): void {
    this.dialogRef.close();
  }

  rejectButtonPressed(): void {
    this.dialogRef.close({
      rationale: this.rationale.value
    });
  }
}
