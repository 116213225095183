import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from './../../environments/environment';
import Model from '../models/model.model';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {
  constructor(
    private http: HttpClient
  ) {}

  public getModels(ids: number[] | null): Observable<Model[]> {
    var path = `/catalog/models`;
    if (!!ids) {
      path += `?ids=${ids.join(',')}`
    }
    return this.http.get<Model[]>(environment.apiUrl + path).pipe(
      map(models => models.map(model => Object.assign(new Model(), model)))
    );
  }

  public searchModels(query: string): Observable<any> {
    let params = new HttpParams().set('query', query).set('type[]', 'MODEL');
    return this.http.get<any>(environment.apiUrl + "/catalog/search", { params: params });
  }
}
