import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Tag from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  constructor(private http: HttpClient) { }
  
  public getTags(ids: number[] | null): Observable<any> {
    var path = `/marketplace/tags`;
    if (!!ids) {
      path += `?ids=${ids}`
    }
    return this.http.get<Tag[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return response.body!.map(t => Object.assign(new Tag(), t))
      })
    );
  }
}
